import React from 'react';
import './WelcomePage.css';

const WelcomePage = () => {
  return (
    <div className='welcome-content'>
      <div className='section'>
        <div className='row'>
          <div className='col-md-2'></div>
          <div className='col-md-8'>
            <h1>Olá, seja bem-vindo(a)!</h1>
            <p>
              Nós somos a igreja Ação do Espírito e é um prazer te receber por aqui, espero que você tenha sido abençoado por Deus nessa sua primeira visita a nossa igreja. Nós cremos que há uma história de Deus para sua vida, e você pode ser envolvido dentro dos propósitos do Senhor.
            </p>
            <p>
              Nós somos uma igreja, uma família, um lugar para você pertencer e ser. Conheça um pouco mais da nossa história a partir desse site e também nas nossas redes sociais, mas, o que esperamos mesmo é te ver por aqui e ter esse contato de forma pessoal.
            </p>
            <p>
              Você também pode procurar um dos nossos lideres e participar de umas das nossas Ação House (células em casa), nós temos vários grupos espalhados na cidade e nas regiões metropolitanas,eu tenho certeza que isso vai te encorajar a viver os planos de Deus e desenvolver uma família espiritual para você.
            </p>
            <p>
              Por último, fica aqui um presente nosso para você:<br/>
              Baixe um dos nossos livros de forma gratuita <a href='https://acaodoespirito.com.br/livros' target='_blank'>clicando nesse link</a>, receba isso com muito amor.
              <br/><br/>
              Deus abençoe, e seja cheio do Espírito Santo.
              <br/>
              Pr. Thiago Simeão - Pra. Giu Simeão & Igreja Ação do Espírito.</p>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-2'></div>
          <div className='col-md-8'>
            <h3>Nossos líderes</h3>
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">Nomes</th>
                  <th scope="col">Local Ação House</th>
                  <th scope="col">Contato</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Thiago e Giu</td>
                  <td>Campina Grande do Sul/<br/>Quatro Barras</td>
                  <td>
                    <a href='https://api.whatsapp.com/send/?phone=5541996051873&text=Oi'>(41)99605-1873</a>
                    <br/><br/>
                    <a href='https://api.whatsapp.com/send/?phone=5541999282127&text=Oi'>(41)99928-2127</a>
                  </td>
                </tr>
                <tr>
                  <td>Silvia e Juarildo</td>
                  <td>Curitiba (Bairro Alto)/<br/>Pinhais</td>
                  <td>
                    <a href='https://api.whatsapp.com/send/?phone=5541996170414&text=Oi'>(41)99617-0414</a>
                    <br/><br/>
                    <a href='https://api.whatsapp.com/send/?phone=5541999246842&text=Oi'>(41)99924-6842</a>
                  </td>
                </tr>
                <tr>
                  <td>Flávia, Fran e Alex</td>
                  <td>Curitiba<br/>(Sta. Cândida/Boa Vista)<br/>Colombo</td>
                  <td>
                    <a href='https://api.whatsapp.com/send/?phone=5541995496790&text=Oi'>(41)99549-6790</a>
                    <br/><br/>
                    <a href='https://api.whatsapp.com/send/?phone=5541984730603&text=Oi'>(41)98473-0603</a>
                  </td>
                </tr>
                <tr>
                  <td>Lucas e Andri</td>
                  <td>Curitiba<br/>(Centro/Bom retiro)</td>
                  <td>
                    <a href='https://api.whatsapp.com/send/?phone=5541996504080&text=Oi'>(41)99650-4080</a>
                    <br/><br/>
                    <a href='https://api.whatsapp.com/send/?phone=5541996166261&text=Oi'>(41)99616-6261</a>
                  </td>
                </tr>
                <tr>
                  <td>Eduardo e Lo</td>
                  <td>Curitiba<br/>(Fazendinha)</td>
                  <td>
                    <a href='https://api.whatsapp.com/send/?phone=5541991866879&text=Oi'>(41)99186-6879</a>
                  </td>
                </tr>
                <tr>
                  <td>Duan e Keyssi</td>
                  <td>Curitiba<br/>(Adolescentes)</td>
                  <td>
                    <a href='https://api.whatsapp.com/send/?phone=5541999425931&text=Oi'>(41)99942-5931</a>
                    <br/><br/>
                    <a href='https://api.whatsapp.com/send/?phone=5541995088445&text=Oi'>(41)99508-8445</a>
                  </td>
                </tr>
                <tr>
                  <td>Jhon e Rayssa</td>
                  <td>São José dos Pinhais</td>
                  <td>
                    <a href='https://api.whatsapp.com/send/?phone=5541998756825&text=Oi'>(41)99875-6825</a>
                    <br/><br/>
                    <a href='https://api.whatsapp.com/send/?phone=5541998964890&text=Oi'>(41)99896-4890</a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className='welcome-video-wrap'>
          <iframe width="560" height="315" src="https://www.youtube.com/embed/FZyZo_TOT6c?si=5dsOzZ2-XF9ahJLc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        </div>

      </div>
    </div>
  );
}

export default WelcomePage;