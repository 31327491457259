const Conferencias = () => {
    return (
        <div className="container text-center">
            <div className="row justify-content-md-enter">
            <div className="row">
                <div className="col">
                Column
                </div>
                <div className="col">
                Column
                </div>
                <div className="col">
                Column
                </div>
            </div>
            </div>
        </div>

    );
}
export default Conferencias;