export const TeamMock = {
    members: [
        {id:0,name:'Thiago'},
        {id:1,name:'Giu'},
        {id:2,name:'Jhonata'},
        {id:3,name:'Rayssa'},
        {id:4,name:'Cida'},
        {id:5,name:'Renato'},
        {id:6,name:'Amanda'},
        {id:7,name:'Carol'},
        {id:8,name:'Lucas'},
        {id:9,name:'Andrielly'},
        {id:10,name:'Willis'},
        {id:11,name:'Thalita'},
        {id:12,name:'Alex'},
        {id:13,name:'Fran'},
        {id:14,name:'Kaique'},
        {id:15,name:'Vini'},
        {id:16,name:'Fabielle'},
        {id:17,name:'Junior'},
        {id:18,name:'Eduardo'},
        {id:19,name:'Gabriel'},
    ],
    tasks: [
        {id:0,name:'Limpeza'},
        {id:1,name:'Abertura'},
        {id:2,name:'Decoração'},
        {id:3,name:'Recepção'},
        {id:4,name:'Louvor'},
        {id:5,name:'Palavra'},
        {id:6,name:'Ofertas'},
        {id:7,name:'Mídia'},
        {id:8,name:'Datashow'},
        {id:9,name:'Crianças'},
        {id:10,name:'Bebês'},
        {id:11,name:'Avisos'},
    ],
    schedules:[
        {
            id:0,
            day:12,
            month:12,
            wDay:'Domingo',
            tasks:[
                {mId:0,tId:0},
                {mId:1,tId:1},
                {mId:2,tId:2},
                {mId:7,tId:3},
                {mId:6,tId:4},
                {mId:12,tId:5},
                {mId:13,tId:6},
                {mId:5,tId:7},
                {mId:7,tId:8},
                {mId:11,tId:9},
                {mId:10,tId:10},
                {mId:19,tId:11},
            ]
        },
        {
            id:1,
            day:19,
            month:12,
            wDay:'Domingo',
            tasks:[
                {mId:3,tId:3},
                {mId:4,tId:4},
                {mId:5,tId:5},
            ]
        },
    ]
}