export const EventsMock = [
    /*{
        id:0,
        day: '12',
        month: 'OUT',
        date:'12/10/2022 21:00',
        title: 'Evento especial',
        desc: 'Detalhes',
        link: null
    },
    {
        id:1,
        day: '13',
        month: 'OUT',
        date:'13/10/2022 21:00',
        title: 'Evento especial',
        desc: 'Detalhes',
        link: null
    },*/
];